import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Header from "./component/Header";
// import Home from './pages/Home';
import Blog from './pages/Blog';
// import About from './pages/About';
import Contact from "./pages/Contact";
import Frontpage from "./component/Frontpage";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Frontpage />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
