import React, { useState, useEffect } from 'react';
import axios from 'axios';
// // import './BlogList.css'; // Ensure you have the necessary CSS

function BlogList() {
  const [blogs, setBlogs] = useState([]);


  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://www.blogbackend.trollsufficient.com/blog/all');
        console.log('API Response:', response.data); // Log response for debugging
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="space-y-6 mb-12 md:mt-24">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {blogs.length === 0 ? (
          <p>No blogs available.</p>
        ) : (
          blogs.map(blog => (
            <article 
              key={blog._id} 
              className={`bg-white p-4 rounded shadow-md transform transition-transform duration-300 hover:scale-105 cursor-pointer ${!blog.photo.length ? 'simple-blog' : 'main-blog'}`}
            >
              {blog.photo.length > 0 && (
                <img 
                  src={blog.photo[0]} 
                  alt={blog.Title} 
                  className="w-full h-48 object-cover rounded mb-4" 
                />
              )}
              <h2 className="text-xl font-semibold">{blog.Title}</h2>
              <p className="text-gray-600 text-sm mb-2">{new Date(blog.createdAt).toLocaleDateString()} by Unknown</p>
              <p>{blog.content}</p>
            </article>
          ))
        )}
      </div>
    </div>
  );
}

export default BlogList;
