import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div >
      <header className="text-white fixed top-0 left-0 right-0 z-30">
        <nav
          className="container flex justify-between items-center p-4"
          style={{
            background: 'rgb(86,91,234)',
            background: 'radial-gradient(circle, rgba(86,91,234,1) 16%, rgba(123,86,206,1) 85%)'
          }}
        >
          <h1 className="text-2xl font-bold">LAS WAR : SURVIVAL GUIDE</h1>

          <button
            className="md:hidden block focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Navigation links */}
          <ul
            className={`md:flex md:space-x-8 ${
              isMenuOpen ? "block" : "hidden"
            } absolute md:static left-0 top-16 md:top-0 w-full md:w-auto bg-gray-800 md:bg-transparent z-20`}
          >
            <li>
              <Link to="/" className="block py-2 px-4 hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/blog" className="block py-2 px-4 hover:underline">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/about" className="block py-2 px-4 hover:underline">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className="block py-2 px-4 hover:underline">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default Header;
