import React from 'react';

// Example blog data
const blogs = Array.from({ length: 20 }, (_, index) => ({
  name: `Blog Name ${index + 1}`,
  date: `2024-09-${index + 1}`,
  content: `This is the content for blog ${index + 1}. It provides a brief overview of what this blog post is about. This content is only two lines long.`
}));

const Blog = () => {
  return (
    <div>
      {blogs.map((blog, index) => (
        <div key={index} style={styles.blogContainer}>
          <img 
            src="https://via.placeholder.com/150" 
            alt={`Blog ${index + 1}`} 
            style={styles.image} 
          />
          <h2 style={styles.title}>{blog.name}</h2>
          <p style={styles.date}>{blog.date}</p>
          <p style={styles.content}>{blog.content}</p>
        </div>
      ))}
    </div>
  );
};

// Inline styles for simplicity
const styles = {
  blogContainer: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop:'100px'
  },
  image: {
    width: '150px',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginBottom: '8px'
  },
  title: {
    fontSize: '1.5em',
    margin: '0 0 8px 0'
  },
  date: {
    fontSize: '0.9em',
    color: '#888',
    margin: '0 0 8px 0'
  },
  content: {
    fontSize: '1em',
    margin: '0'
  }
};

export default Blog;
