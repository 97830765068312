import React from 'react'
import Carousel from "../component/Carausal";
import BlogList from "../component/Bloglist";
import Header from './Header';
const Frontpage = () => {
  return (
    <div style={{
      backgroundImage: 'linear-gradient(41deg, rgba(255,0,0,1) 0%, rgba(85,9,190,1) 35%, rgba(255,255,255,1) 89%)'
    }}
    >
        <div >
          <Header/>
        </div>
        <div className="container mx-auto px-4 py-6" style={{ backgroundColor: 'linear-gradient(41deg, rgba(255,0,0,1) 0%, rgba(85,9,190,1) 35%, rgba(255,255,255,1) 89%)',}}>
        <Carousel />
        <BlogList />
        </div>
    </div>
  )
}

export default Frontpage